import { ChakraProvider} from '@chakra-ui/react'
import theme from "./styles/theme.js"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home.js';
import NotFound from './pages/NotFound.js';
import Post from './pages/Post.js';



		//<Button colorScheme='blue' onClick={()=>toggleLang()}>Button</Button>

function App() {
	/*
	const [lang, setLang] = useState('en');

	const toggleLang = () => {
		if (lang === 'en')
			setLang('es');
		else
			setLang('en');
	}
	*/

  return (
	<ChakraProvider resetCSS theme={theme}>
		<Router>
 	     <Routes>
 	       <Route path="/" element={<Home />} />
			<Route path="/post/:slug" element={<Post />} />
 	       <Route path="*" element={<NotFound />} />
 	     </Routes>
 	   </Router>
    </ChakraProvider>
  );
}

export default App;

