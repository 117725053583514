 
import { Avatar, Container, Text, Box, Flex, useMediaQuery } from '@chakra-ui/react'

export default function Header(){

	const [isLargerThan480] = useMediaQuery("(min-width: 690px)");
 return (
	<Container maxWidth='100ch' mb={1} h={[300, 250, 200, 130]}>
			<Box mt="15%" >
				 <Flex alignItems="center">
			{isLargerThan480 && (
      <Avatar
        size="xl"
        src="/avatar.png"
        borderRadius="full"
        mr={1}
          mt={-7}
      />)}
      				 <Box>
				<Text as='h1' fontSize='6xl' fontWeight='600' lineHeight="100%" color='custom.50'  >Alberto García</Text>
				<Text as='h2' fontSize='6xl' fontWeight='600'color='custom.100' lineHeight="100%">C, C++ and Javascript developer;</Text>
					 </Box>
    </Flex>
				
			</Box>
			

		</Container>
 )
}