// components/Post.js
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Global } from "@emotion/react";
import { Link } from 'react-router-dom';
import ListFiles from '../components/ListFiles';
import { 
  Text,
  Divider,
  Box,
  Container,
  Avatar,
  Fade,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';

function Post() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const fetchController = useRef(null);
  const [state, setState] = useState({
    post: null,
    loading: true,
    error: null
  });

  useEffect(() => {
    if (fetchController.current) {
      fetchController.current.abort();
    }

    fetchController.current = new AbortController();
    const currentController = fetchController.current;

    const fetchPost = async () => {
      try {
        setState(prev => ({ ...prev, loading: true }));

        const response = await fetch(
          `https://storage.googleapis.com/albgarci/${slug}.json`,
          { signal: currentController.signal }
        );
        
        if (!response.ok) {
          if (response.status === 404) {
            navigate('/404');
            return;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (currentController === fetchController.current) {
          setState({
            post: data,
            loading: false,
            error: null
          });
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          return;
        }
        
        if (currentController === fetchController.current) {
          setState({
            post: null,
            loading: false,
            error: error.message
          });
        }
      }
    };

    fetchPost();

    return () => {
      currentController.abort();
    };
  }, [slug, navigate]);

  const { post, loading, error } = state;

  if (loading) {
    return (
      <Container centerContent py={10}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxW="container.md" py={5}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error al cargar el post</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </Container>
    );
  }

  if (!post) {
    return null;
  }

  return (
    <Fade in>
       <Global
        styles={{
          p: {
            marginTop: "1rem",
            marginBottom: "1rem",
            color: "var(--chakra-colors-custom2-50)"
          },
          img: {
            marginTop: "1rem",
            marginBottom: "1rem",
          },
          ul: {
            paddingLeft: "3rem",
            color: "var(--chakra-colors-custom2-50)"
          },
          ol: {
            paddingLeft: "3rem",
            color: "var(--chakra-colors-custom2-50)"
          },
          strong: {
            color: "#64ffda",
            fontWeight: '400'
          },
          h2: {
            fontSize: "1.5em",
            color: "var(--chakra-colors-custom2-50)"
          },
          code: {
            display: "inline-block",
            backgroundColor: "#242d47",
            color: "#ffffff",
            padding: "4px 6px",
            borderRadius: "4px",
            fontFamily: "Courier New, Courier, monospace",
            fontSize: "0.9em",
          }
        }}
      />
      
      <Container maxW="container.md" maxWidth='100ch' py={5}>
        <Link to="/" style={{color: "#64ffda"}}>Go to homepage &nbsp;-&nbsp; See all posts</Link>
        <Box mt="1%">
		  <Text as='h1' fontSize='5xl' fontWeight='600' lineHeight="100%" color='custom.50'  >{post.title}</Text>
          Published on {post.date} &nbsp;by &nbsp;
          
          <Link to="/"
            >
          <Avatar size="xs" src="/avatar.png" borderRadius="full"></Avatar>Alberto García</Link>
          <br/>
		  <Divider mb={5}/>

          <Box dangerouslySetInnerHTML={{__html: post.content }}></Box>
        </Box>
        

		<ListFiles/>
        <Link to="/" style={{color: "#64ffda"}}>Go to homepage &nbsp;-&nbsp; See all posts</Link>
      </Container>
    </Fade>
  );
}

export default Post;