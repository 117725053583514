import { Box, Container, Text, Divider, Fade, VStack } from '@chakra-ui/react';
import React from 'react';
import Resume from '../components/Resume';
import Contact from '../components/Contact';

function Home() {
  return (
    <Fade in initial={{ opacity: 0 }} transition={{ duration: 0.5 }}>
      <VStack spacing={0} align="stretch">
        <Box>
          <Resume language='en' />
          <Container maxWidth="99ch">
            <Text
              fontWeight={599}
              as='span'
              color='custom.300'
              _hover={{
                color: 'custom.400',
                transition: 'color 0.2s ease-in-out'
              }}
            >
              Contact me
            </Text>

            <Divider 
              mb={4}
              opacity={0.6}
              transition="opacity 0.2s ease-in-out"
              _hover={{ opacity: 1 }}
            />
            
            <Contact />
          </Container>
        </Box>
        <Box h={19} aria-hidden="true" />
      </VStack>
    </Fade>
  );
}

export default Home;