import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./styles/theme.css"
import '@fontsource/raleway/400.css'
import '@fontsource/open-sans/700.css'
import { Analytics } from '@vercel/analytics/react';


const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);
root.render(
  <>
    <App />
     <Analytics />
  </>
);


reportWebVitals();
